import { Id } from './id';

const COMPANY_ID_KEY = 'https://staging-api.equipmentshare.com/es_company_id';

export type User = {
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	[COMPANY_ID_KEY]: Id;
	'https://staging-api.equipmentshare.com/es_company_name': string;
	'https://staging-api.equipmentshare.com/es_identity_id': string;
	'https://staging-api.equipmentshare.com/es_token_type': string;
	'https://staging-api.equipmentshare.com/es_user_email': string;
	'https://staging-api.equipmentshare.com/es_user_id': Id;
	'https://staging-api.equipmentshare.com/es_user_name': string;
	'https://staging-api.equipmentshare.com/es_user_read_only': boolean;
	'https://staging-api.equipmentshare.com/es_user_security_level_id': number;
	locale: string; // probably should define a language type at some point but not a concern right now
	name: string;
	nickname: string;
	picture: string;
	sub: string;
	updated_at: string; // probably should define a datestring type at some point
};

export const getCompanyId = (user: User) => user[COMPANY_ID_KEY];
