import { Switch, Route, Redirect } from 'react-router-dom';
import Entities from './screens/entities/entities';
import VendorDetails from './screens/vendor_details/vendorDetails';
import './AppRouter.css';
import Contacts from './screens/contacts/contacts';
import { EntityFormScreen } from './screens/entities/entity_form/entityFormScreen';
import { ContactFormScreen } from './screens/contacts/contactFormScreen';

function AppRouter() {
	return (
		<div id="RouterContainer">
			<div id="Routes">
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/entities" />} />
					<Route exact path="/entities">
						<Entities />
					</Route>
					<Route exact path="/entities/add">
						<EntityFormScreen />
					</Route>
					<Route exact path="/entities/edit/:id">
						<EntityFormScreen />
					</Route>
					<Route exact path="/entities/:id">
						<VendorDetails />
					</Route>
					<Route path="/entity-contacts/add">
						<ContactFormScreen />
					</Route>
					<Route exact path="/entity-contacts">
						<Contacts />
					</Route>
					<Route path="/entity-contacts/edit/:id">
						<ContactFormScreen />
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default AppRouter;
