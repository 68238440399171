import { withAuthenticationRequired } from '@equipmentshare/auth0-react';
import { Navbar } from './Navbar';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import { EuiProvider, THEME_TOKENS } from '@equipmentshare/ds2';

function App() {
	return (
		<BrowserRouter>
			<EuiProvider modify={THEME_TOKENS}>
				<Navbar />
				<AppRouter />
			</EuiProvider>
		</BrowserRouter>
	);
}

export default withAuthenticationRequired(App);
