import React from 'react';
import {
	Criteria,
	EuiBasicTable,
	EuiButton,
	EuiEmptyPrompt,
	EuiTableSortingType,
	EuiText,
	Pagination,
} from '@equipmentshare/ds2';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import columns from './columns';

import typeColumn from './columns/type';

export type ContactsTableProps = {
	data?: EntityContact[];
	entityId?: number;
	loading?: boolean;
	sorting?: EuiTableSortingType<EntityContact>;
	pagination?: Pagination;
	onChange?: (criteria: Criteria<EntityContact>) => void;
};

const ContactsTable: React.FC<React.PropsWithChildren<ContactsTableProps>> = ({
	data = [],
	loading = false,
	...props
}) => {
	return props.entityId && !data.length && !loading ? (
		<EuiEmptyPrompt
			title={<h1>No contacts</h1>}
			titleSize="s"
			body={<EuiText>Edit the vendor to add contacts</EuiText>}
			actions={
				<EuiButton
					fill
					color="primary"
					size="s"
					href={`/entities/edit/${props.entityId}`}
				>
					Edit
				</EuiButton>
			}
		/>
	) : (
		<EuiBasicTable
			columns={
				props.entityId ? [...columns, typeColumn(props.entityId)] : columns
			}
			items={data}
			loading={loading}
			noItemsMessage="No Contacts"
			onChange={props.onChange}
			pagination={props.pagination}
			rowHeader="email_address"
			sorting={props.sorting}
		/>
	);
};

export default ContactsTable;
