import { Entity } from '@equipmentshare/pcrm-domain/dist/models';
import RouterLink from '../../../RouterLink';

function render(name: string, entity: Entity) {
	return <RouterLink location={`/entities/${entity.entity_id}`} name={name} />;
}

export default {
	field: 'name',
	name: 'Name',
	render: render,
	sortable: true,
	width: '30%',
};
