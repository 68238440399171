import { ReactElement } from 'react';
import '../screens.css';

import { PageHeader } from '../../components/PageHeader/PageHeader';
import VendorsTable from '../../components/Tables/VendorsTable/VendorsTable';
import {
	EuiPage,
	EuiPageContent,
	EuiPageBody,
	EuiSpacer,
} from '@equipmentshare/ds2';

export function Entities(): ReactElement {
	return (
		<EuiPage direction="column">
			<PageHeader
				buttonLink="/entities/add"
				buttonTitle="Add Vendor"
				headerTitle="Vendors"
			/>
			<EuiPageBody>
				<EuiPageContent
					hasBorder={false}
					hasShadow={false}
					paddingSize="none"
					color="transparent"
					borderRadius="none"
				>
					<EuiSpacer />
					<VendorsTable />
				</EuiPageContent>
			</EuiPageBody>
		</EuiPage>
	);
}

export default Entities;
