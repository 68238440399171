import {
	EuiButtonEmpty,
	EuiHorizontalRule,
	EuiTitle,
} from '@equipmentshare/ds2';
import { ReactElement } from 'react';
import './SettingsHeader.css';
import { Title } from '../../../../Title/Title';

type SettingsHeaderProps = {
	onClick: () => void;
	title: string;
	testId: string;
	editFormActive?: boolean;
	formDisabled?: boolean;
};

export function SettingHeader(props: SettingsHeaderProps): ReactElement {
	return (
		<>
			<div id="VendorSettingsHeader" data-testid={props.testId}>
				<Title size={'s'} title={props.title} />
				<EuiTitle size={'xxs'} id="SettingsEditButton">
					<EuiButtonEmpty
						onClick={props.onClick}
						disabled={props.editFormActive || props.formDisabled}
					>
						Edit
					</EuiButtonEmpty>
				</EuiTitle>
			</div>
			<EuiHorizontalRule
				size={'full'}
				margin={'xs'}
				style={{ marginTop: '0px' }}
			/>
		</>
	);
}
