import { useParams } from 'react-router';
import { useEntity } from '@equipmentshare/pcrm-domain/dist/queries/entity';
import { useEntityContacts } from '@equipmentshare/pcrm-domain/dist/queries/entity_contacts';
import { getPurchasesAPIHost } from '../../services/host';
import { useAuthenticationDetails } from '../../hooks/useAuthenticationDetails';
import { ReactElement, useEffect, useState } from 'react';
import VendorSettings from '../../components/VendorDetails/VendorSettings/VendorSettings';
import VendorDetailsHeader from '../../components/VendorDetails/VendorDetailsHeader/VendorDetailsHeader';
import { VendorDetailsCard } from '../../components/VendorDetails/VendorDetailsCard/VendorDetailsCard';
import VendorDetailsSelector, {
	VendorDetailsSelectorItem,
} from '../../components/VendorDetails/VendorDetailsSelector/VendorDetailsSelector';
import './vendorDetails.css';
import ContactsTable from '../../components/Tables/ContactsTable/ContactsTable';
import {
	activateEntityMutation,
	deactivateEntityMutation,
} from '@equipmentshare/pcrm-domain/dist/mutations/entities';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import {
	EuiButton,
	EuiCallOut,
	EuiSpacer,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPage,
	EuiText,
	Direction,
	Criteria,
	EuiTableSortingType,
} from '@equipmentshare/ds2';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';

export type EntityRouteParams = { id: string };

const selectorItems: VendorDetailsSelectorItem[] = [
	{ label: 'Contacts' },
	{ label: 'Vendor' },
	// Can be added back when PO functionality is implemented
	// { label: 'Purchase Orders' },
];

export function VendorDetails(): ReactElement {
	const { id } = useParams<EntityRouteParams>();
	const { accessToken } = useAuthenticationDetails();
	const [sortField, setSortField] = useState<keyof EntityContact>('first_name');
	const [sortDirection, setSortDirection] = useState<Direction>('desc');
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [isActive, setIsActive] = useState<boolean | undefined>();

	const { data: vendor } = useEntity(getPurchasesAPIHost(), accessToken, id);
	useEffect(() => {
		setIsActive(vendor?.active);
	}, [vendor]);
	const {
		isLoading: isLoadingEntityContacts,
		data: paginatedEntityContact,
	} = useEntityContacts(getPurchasesAPIHost(), accessToken, {
		entity_id: id,
		sort_col: sortField,
		sort_dir: sortDirection,
	});

	const deactivateMutation = deactivateEntityMutation(
		getPurchasesAPIHost(),
		accessToken
	);
	const deactivateEntity = async () => {
		vendor && deactivateMutation.mutate(vendor.entity_id);
	};
	useEffect(() => {
		deactivateMutation.data && setIsActive(deactivateMutation.data.active);
	}, [deactivateMutation.data?.active]);

	const activateMutation = activateEntityMutation(
		getPurchasesAPIHost(),
		accessToken
	);
	const activateEntity = async () => {
		vendor && activateMutation.mutate(vendor.entity_id);
	};
	useEffect(() => {
		activateMutation.data && setIsActive(activateMutation.data.active);
	}, [activateMutation.data?.active]);

	const sorting: EuiTableSortingType<EntityContact> = {
		sort: {
			field: sortField,
			direction: sortDirection,
		},
	};

	const onTableChange = ({
		sort = { field: 'entity_contact_id', direction: 'desc' },
	}: Criteria<EntityContact>) => {
		const { field: sortField, direction: sortDirection } = sort;

		setSortField(sortField);
		setSortDirection(sortDirection);
	};

	return (
		<EuiPage direction="column">
			{(activateMutation.error || deactivateMutation.error) && (
				<ErrorMessage className={'VendorDetailError'}>
					<p>
						{vendor?.name} was not{' '}
						{activateMutation.error ? 'reactivated' : 'deactivated'}. Please try
						again.
					</p>
				</ErrorMessage>
			)}
			{!!vendor && (
				<VendorDetailsHeader
					vendor={vendor}
					deactivate={deactivateEntity}
					isActive={isActive}
					isDeactivating={deactivateMutation.isLoading}
				/>
			)}
			{!!vendor && !isActive && (
				<>
					<EuiSpacer size="l" />
					<EuiCallOut title={'Vendor Disabled'} size="s">
						<EuiFlexGroup direction="column">
							<EuiFlexItem>
								<EuiText size="s">
									Entity is disabled and can no longer be used as a vendor. To
									allow purchases from this entity, re-enable the vendor.
								</EuiText>
							</EuiFlexItem>
							<EuiFlexItem
								grow={false}
								style={{ marginBottom: '0px', marginTop: '0px' }}
							>
								<div>
									<EuiButton
										size="s"
										color="primary"
										fill
										onClick={activateEntity}
										isDisabled={activateMutation.isLoading}
									>
										Re-enable vendor
									</EuiButton>
								</div>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiCallOut>
				</>
			)}
			<EuiSpacer size="l" />
			{!!vendor && <VendorDetailsCard vendor={vendor} />}
			<EuiSpacer size="l" />
			<VendorDetailsSelector
				onSelect={setSelectedIndex}
				selectedIndex={selectedIndex}
				items={selectorItems}
			/>
			{selectedIndex === 0 && (
				<div id="EntityContactsTableContainer">
					<ContactsTable
						data={paginatedEntityContact?.data}
						entityId={parseInt(id)}
						loading={isLoadingEntityContacts}
						sorting={sorting}
						onChange={onTableChange}
					/>
				</div>
			)}
			{selectedIndex === 1 && (
				<VendorSettings
					vendor={vendor}
					contacts={paginatedEntityContact?.data}
				/>
			)}
		</EuiPage>
	);
}

export default VendorDetails;
