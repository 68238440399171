import React from 'react';
import {
	EuiButton,
	EuiFieldSearch,
	EuiFlexGroup,
	EuiFlexItem,
} from '@equipmentshare/ds2';
import { EntityContactFilters } from '@equipmentshare/pcrm-domain/dist/models';

export type ContactFilters = Pick<EntityContactFilters, 'search'>;

type Props = {
	onChange: (contactFilters: ContactFilters) => void;
};

export const ContactTableFilters: React.FC<Props> = (props) => {
	const [searchValue, setSearchValue] = React.useState('');

	const onSearchChange = (value: string) => {
		props.onChange({ search: value });
	};

	return (
		<EuiFlexGroup gutterSize={'none'} style={{ width: '352px' }}>
			<EuiFlexItem>
				<EuiFieldSearch
					isClearable={true}
					onChange={(e) => setSearchValue(e.target.value)}
					onSearch={onSearchChange}
					placeholder="Search by Name"
					value={searchValue}
					style={{ width: '352px', marginRight: '8px' }}
				/>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiButton onClick={() => onSearchChange(searchValue)} color="text">
					Search
				</EuiButton>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
};

export default ContactTableFilters;
