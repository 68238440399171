import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@equipmentshare/auth0-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import '@equipmentshare/shared-styling/css/theme-colors.css';
import '@equipmentshare/es-design-tokens-css/dist/design-tokens.css';

import '@equipmentshare/shared-styling/css/theme-colors.css';
import '@equipmentshare/es-design-tokens-css/dist/design-tokens.css';
import { HelmetProvider } from '@equipmentshare/ds2-helmet';

const queryClient = new QueryClient();

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<Auth0Provider
				domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
				clientId={process.env.REACT_APP_AUTH0_CLIENTID ?? ''}
				redirectUri={window.location.origin}
				audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}
			>
				<HelmetProvider appName="Platform CRM" faviconPath="/favicon.png">
					<App />
				</HelmetProvider>
			</Auth0Provider>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
