import React from 'react';
import { EuiTab, EuiTabs } from '@equipmentshare/ds2';
import './VendorDetailsSelector.css';

export type VendorDetailsSelectorItem = {
	label: string;
};

type VendorDetailsSelectorProps = {
	onSelect: (value: number) => void;
	selectedIndex: number;
	items: VendorDetailsSelectorItem[];
};

export const VendorDetailsSelector: React.FC<VendorDetailsSelectorProps> = (
	props
) => (
	<EuiTabs data-testid="tab-selector">
		{props.items.map((item, i) => (
			<EuiTab
				data-testid={`${item.label}-tab`}
				key={i}
				onClick={() => props.onSelect(i)}
				isSelected={i === props.selectedIndex}
			>
				{item.label}
			</EuiTab>
		))}
	</EuiTabs>
);

export default VendorDetailsSelector;
