export function formatPhoneNumber(phoneNumber: string) {
	let formatted = '';
	if (phoneNumber.length > 0) {
		const currentValue = phoneNumber.replace(/[^\d]/g, '');
		const firstThree = currentValue.substring(0, 3);
		const secondThree = currentValue.substring(3, 6);
		const lastFour = currentValue.substring(6);
		formatted =
			currentValue.length < 4
				? `(${firstThree}`
				: currentValue.length > 6
				? `(${firstThree}) ${secondThree}-${lastFour}`
				: `(${firstThree}) ${secondThree}`;
	}
	return formatted;
}
