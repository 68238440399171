import { ReactElement } from 'react';
import { useHistory } from 'react-router';
import {
	EuiButton,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiTitle,
} from '@equipmentshare/ds2';

type PageHeaderProps = {
	buttonLink: string;
	buttonTitle: string;
	headerTitle: string;
};

export function PageHeader(props: PageHeaderProps): ReactElement {
	const history = useHistory();

	return (
		<EuiPageHeader alignItems="center">
			<EuiPageHeaderSection>
				<EuiTitle size="s">
					<h1>{props.headerTitle}</h1>
				</EuiTitle>
			</EuiPageHeaderSection>
			<EuiPageHeaderSection>
				<EuiButton
					title={props.buttonTitle}
					color="primary"
					fill
					onClick={() => history.push(props.buttonLink)}
				>
					{props.buttonTitle}
				</EuiButton>
			</EuiPageHeaderSection>
		</EuiPageHeader>
	);
}
