import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import { EuiTableFieldDataColumnType } from '@equipmentshare/ds2';
import RouterLink from '../../../RouterLink';

function sortable(contact: EntityContact) {
	return `${contact?.first_name} ${contact?.last_name}`;
}

function render(
	_firstName: EntityContact['first_name'],
	contact: EntityContact
) {
	return (
		<RouterLink
			location={`/entity-contacts/edit/${contact.entity_contact_id}`}
			name={sortable(contact)}
		/>
	);
}

const nameColumn: EuiTableFieldDataColumnType<EntityContact> = {
	field: 'first_name',
	name: 'Name',
	render: render,
	sortable,
};

export default nameColumn;
