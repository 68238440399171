import React from 'react';
import {
	EuiText,
	EuiIcon,
	EuiFlexGroup,
	useEuiTheme,
} from '@equipmentshare/ds2';

type Props = {
	name: string;
	required?: boolean;
};

const Label: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const theme = useEuiTheme();
	return (
		<div className="label">
			<EuiFlexGroup gutterSize="none">
				<EuiText
					size="s"
					style={{ fontWeight: theme.euiTheme.font.weight.semiBold }}
				>
					{props.name}
				</EuiText>
				{!!props.required && (
					<EuiIcon size="s" color="danger" type="asterisk" />
				)}
			</EuiFlexGroup>
		</div>
	);
};

export default Label;
