import { Entity } from '@equipmentshare/pcrm-domain/dist/models';
import { ReactElement } from 'react';
import './VendorDetailsCard.css';
import { formatAddress } from '../../../utils/formatAddress';
import {
	EuiDescriptionList,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPanel,
	EuiText,
	useEuiTheme,
} from '@equipmentshare/ds2';
import { FlexItemGrowSize } from '@elastic/eui/src/components/flex/flex_item';
import { useAuthenticationDetails } from 'hooks/useAuthenticationDetails';
import { useIsFeatureCompany } from 'hooks/useIsFeatureCompany';

type VendorDetailsCardProps = {
	vendor: Entity;
};

type Field = {
	label: string;
	text: string;
	flex: FlexItemGrowSize;
};

export function VendorDetailsCard(props: VendorDetailsCardProps): ReactElement {
	const theme = useEuiTheme();
	const { user } = useAuthenticationDetails();
	const isFeatureCompany = useIsFeatureCompany(user);

	const fields: Field[] = [
		{
			label: 'Type',
			text: props.vendor.entity_type.name,
			flex: 1,
		},
		{
			label: 'Primary Contact',
			text: !!props.vendor.primary_contact
				? `${props.vendor.primary_contact?.first_name} ${props.vendor.primary_contact?.last_name}`
				: '-',
			flex: 2,
		},
		{
			label: 'Address',
			text: formatAddress(props.vendor?.business_address || null),
			flex: 4,
		},
		{
			label: 'Customer',
			text: props.vendor.is_customer ? 'Yes' : '-',
			flex: 2,
		},
		{
			label: 'Vendor',
			text: props.vendor.is_vendor ? 'Yes' : '-',
			flex: 2,
		},
	];

	const companySpecificFields = fields.filter(
		(field) => isFeatureCompany || field.label !== 'Customer'
	);

	return (
		<EuiPanel
			style={{
				backgroundColor: theme.euiTheme.colors.lightestShade,
				borderRadius: theme.euiTheme.size.s,
			}}
			grow={true}
			hasShadow={false}
			hasBorder={false}
			data-testid="details-card"
		>
			<EuiFlexGroup
				gutterSize="none"
				alignItems="flexStart"
				justifyContent="center"
				style={{ height: '100%' }}
			>
				{companySpecificFields.map((field, i) => (
					<EuiFlexItem grow={field.flex} key={i}>
						<EuiDescriptionList
							textStyle="reverse"
							align="left"
							listItems={[
								{
									title: (
										<EuiText size="s" color="subdued">
											{field.label}
										</EuiText>
									),
									description: <EuiText size="s">{field.text}</EuiText>,
								},
							]}
						/>
					</EuiFlexItem>
				))}
			</EuiFlexGroup>
		</EuiPanel>
	);
}
