export function validateRequired(value: string) {
	return value.length === 0 ? 'Is required' : '';
}

type ValidateDigitsOptions = {
	length?: number;
	optional?: boolean;
};
export function validateDigits(value: string, options?: ValidateDigitsOptions) {
	if (!value && options?.optional) {
		return '';
	}

	if (options?.length && value.length !== options?.length) {
		return `Must be ${options.length} digits`;
	}

	if (!/^\d+$/.test(value)) {
		return 'Must only contain digits';
	}

	return '';
}

type ValidatePostalCodeOptions = {
	optional?: boolean;
};

export function validatePostalCode(
	value: string,
	options?: ValidatePostalCodeOptions
) {
	if (!value && options?.optional) {
		return '';
	}

	if (!/^([A-Z|0-9|\- ])+$/.test(value)) {
		return `Must only contain A-Z, 0-9, hyphen, or space`;
	}

	return '';
}

type ValidateNumberOptions = {
	optional?: boolean;
};

export function validateNumber(value: string, options?: ValidateNumberOptions) {
	return (!value && options?.optional) || !isNaN(parseFloat(value))
		? value.includes('.')
			? 'Must be a whole number'
			: ''
		: 'Must be numeric';
}

type ValidateEmailOptions = {
	optional?: boolean;
};

export function validateEmail(value: string, options?: ValidateEmailOptions) {
	return (!value && options?.optional) || /\S+@\S+\.\S+/.test(value)
		? ''
		: 'Invalid email';
}

type validatePhoneNumberOptions = {
	optional?: boolean;
};

export function validatePhoneNumber(
	value: string,
	options?: validatePhoneNumberOptions
) {
	return (!value && options?.optional) ||
		/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/.test(value)
		? ''
		: 'Invalid phone number';
}
