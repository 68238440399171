import { Entity } from '@equipmentshare/pcrm-domain/dist/models';
import { ReactElement } from 'react';
import { useHistory } from 'react-router';
import './VendorDetailsHeader.css';
import {
	EuiBreadcrumbs,
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiSpacer,
	EuiTitle,
} from '@equipmentshare/ds2';

type VendorDetailsHeaderProps = {
	vendor: Entity;
	isActive?: boolean;
	deactivate: () => void;
	isDeactivating?: boolean;
};

export default function VendorDetailsHeader(
	props: VendorDetailsHeaderProps
): ReactElement {
	const history = useHistory();

	return (
		<EuiPageHeader data-testid="details-header" alignItems="bottom">
			<EuiPageHeaderSection>
				<EuiBreadcrumbs
					breadcrumbs={[
						{
							text: 'Vendors',
							onClick: () => history.push('/'),
						},
						{
							text: props.vendor.name,
						},
					]}
				/>
				<EuiSpacer size="m" />
				<EuiTitle size="s">
					<h1 data-testid="page-title">{props.vendor.name}</h1>
				</EuiTitle>
			</EuiPageHeaderSection>
			<EuiPageHeaderSection>
				<EuiFlexGroup gutterSize="s">
					<EuiFlexItem>
						<EuiButton
							title="Edit"
							color="primary"
							fill
							isDisabled={!props.isActive}
							href={`/entities/edit/${props.vendor.entity_id}`}
							minWidth={0}
						>
							Edit
						</EuiButton>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							title="Disable"
							color="text"
							onClick={props.deactivate}
							isDisabled={props.isDeactivating || !props.isActive}
							minWidth={0}
						>
							Disable
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiPageHeaderSection>
		</EuiPageHeader>
	);
}
