import { ReactElement, useEffect } from 'react';
import {
	EntityLocation,
	EntityState,
	State,
} from '@equipmentshare/pcrm-domain/dist/models';
import { useForm, ValidationRules } from '../../hooks/useForm';
import {
	validatePostalCode,
	validateRequired,
} from '../../services/validation';
import {
	EuiComboBox,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiTitle,
} from '@equipmentshare/ds2';
import Label from 'components/Label';
import './index.css';

export type EntityLocationFormState = {
	street1: string;
	street2: string;
	city: string;
	stateInput: string;
	state: EntityState | null;
	zipCode: string;
};

type EntityLocationFormProps = {
	displayErrors: boolean;
	entityLocationFormChanged: (entityLocation: EntityLocationFormState) => void;
	entityLocationToEdit?: EntityLocation;
	entityStates: EntityState[];
	setIsValid: (hasErrors: boolean) => void;
};

export const entityLocationFormValidationRules: ValidationRules<EntityLocationFormState> = {
	street1: validateRequired,
	city: validateRequired,
	state: (state) => (!state ? 'Is required' : ''),
	zipCode: (zipCode) => validatePostalCode(zipCode),
};

const toComboBoxOptionOption = (state: EntityState) => ({
	key: state.entity_state_id.toString(),
	value: state,
	label: state.abbreviation,
});

export function EntityLocationForm(
	props: EntityLocationFormProps
): ReactElement {
	const {
		fields: entityLocationFields,
		handleChange: handleEntityLocationChange,
		errors: entityLocationErrors,
		hasErrors: entityLocationHasErrors,
		setFields: entityLocationSetFields,
	} = useForm<EntityLocationFormState>(
		{
			street1: '',
			street2: '',
			city: '',
			stateInput: '',
			state: null,
			zipCode: '',
		},
		{ validationRules: entityLocationFormValidationRules }
	);

	useEffect(() => {
		if (props.entityLocationToEdit) {
			entityLocationSetFields({
				// TODO implement country tracking for Entities
				// country: props.entityLocationToEdit.country ?? '',
				street1: props.entityLocationToEdit.street_1 ?? '',
				street2: props.entityLocationToEdit.street_2 ?? '',
				city: props.entityLocationToEdit.city ?? '',
				stateInput: props.entityLocationToEdit.state?.name ?? '',
				state: convertToEntityState(props.entityLocationToEdit.state) ?? null,
				zipCode: props.entityLocationToEdit.zip_code ?? '',
			});
		}
	}, [props.entityLocationToEdit]);

	useEffect(() => {
		props.setIsValid(!entityLocationHasErrors);
	}, [entityLocationHasErrors]);

	useEffect(() => {
		props.entityLocationFormChanged(entityLocationFields);
	}, [entityLocationFields]);

	function convertToEntityState(
		state: State | undefined
	): EntityState | undefined {
		return state?.abbreviation && state?.entity_state_id && state?.name
			? {
					entity_state_id: state.entity_state_id,
					name: state.name,
					abbreviation: state.abbreviation,
			  }
			: undefined;
	}

	const selectedState = !!entityLocationFields.state
		? [toComboBoxOptionOption(entityLocationFields.state)]
		: [];

	const stateOptions = props.entityStates.map(toComboBoxOptionOption);

	return (
		<>
			<EuiFlexItem>
				<EuiTitle size="s">
					<span>Business Address</span>
				</EuiTitle>
			</EuiFlexItem>
			{/* Future Feature Enhancement
			<EuiFlexItem>
				<EuiFormRow 
					label={<Label name="Country"/>}
					>
					<EuiComboBox 
						placeholder='Select'
						options={[ { value: 'hi', label: 'hello'}]}
						rowHeight={30}
						
					/>
				</EuiFormRow>
			</EuiFlexItem> */}
			<EuiFlexItem>
				<EuiFormRow
					data-testid="street1-row"
					label={<Label name="Street 1" required />}
					isInvalid={props.displayErrors && !!entityLocationErrors.street1}
					error={entityLocationErrors.street1}
				>
					<EuiFieldText
						data-testid="street1-input"
						value={entityLocationFields.street1}
						isInvalid={props.displayErrors && !!entityLocationErrors.street1}
						onChange={handleEntityLocationChange('street1')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="street2-row"
					label={<Label name="Street 2" />}
					isInvalid={props.displayErrors && !!entityLocationErrors.street2}
					error={entityLocationErrors.street2}
				>
					<EuiFieldText
						data-testid="street2-input"
						value={entityLocationFields.street2}
						isInvalid={props.displayErrors && !!entityLocationErrors.street2}
						onChange={handleEntityLocationChange('street2')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="city-row"
					label={<Label name="City" required />}
					isInvalid={props.displayErrors && !!entityLocationErrors.city}
					error={entityLocationErrors.city}
				>
					<EuiFieldText
						data-testid="city-input"
						value={entityLocationFields.city}
						isInvalid={props.displayErrors && !!entityLocationErrors.city}
						onChange={handleEntityLocationChange('city')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFlexGroup direction="row" gutterSize="m" responsive={false}>
					<EuiFlexItem grow={false}>
						<EuiFormRow
							data-testid="state-row"
							label={<Label name="State" required />}
							isInvalid={props.displayErrors && !!entityLocationErrors.state}
							error={entityLocationErrors.state}
						>
							<EuiComboBox
								data-testid="state-input"
								isClearable={false}
								style={{ width: '95px' }}
								isInvalid={props.displayErrors && !!entityLocationErrors.state}
								singleSelection={{ asPlainText: true }}
								selectedOptions={selectedState}
								options={stateOptions}
								onChange={handleEntityLocationChange(
									'state',
									([v]) => v?.value ?? null
								)}
								onSearchChange={handleEntityLocationChange(
									'stateInput',
									(v) => v
								)}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiFormRow
							data-testid="zip-code-row"
							label={<Label name="Zip Code" required />}
							isInvalid={props.displayErrors && !!entityLocationErrors.zipCode}
							error={entityLocationErrors.zipCode}
						>
							<EuiFieldText
								data-testid="zip-code-input"
								style={{ width: '95px' }}
								value={entityLocationFields.zipCode}
								isInvalid={
									props.displayErrors && !!entityLocationErrors.zipCode
								}
								onChange={handleEntityLocationChange('zipCode')}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlexItem>
		</>
	);
}

export default EntityLocationForm;
