import { getPromotionLevel, PROD } from './promotionLevel';

export function getESAPIHost(): string {
	return getPromotionLevel() === PROD
		? 'https://api.equipmentshare.com'
		: 'https://staging-api.equipmentshare.com';
}

export function getPurchasesAPIHost(): string {
	return getPromotionLevel() === PROD
		? 'https://api.equipmentshare.com/purchases'
		: 'https://staging-api.equipmentshare.com/purchases';
}
