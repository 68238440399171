import { EuiFormRow, EuiSelect } from '@equipmentshare/ds2';
import _ from 'lodash';
import { EntityNetTerms } from '@equipmentshare/pcrm-domain/dist/models';

type EntityNetTermsDropdownProps = {
	options: EntityNetTerms[];
	onChange: (entityNetTerm: EntityNetTerms) => void;
	selectedId: number | undefined;
};

export function EntityNetTermsDropdown(props: EntityNetTermsDropdownProps) {
	const netTermOptions = props.options.map((term) => {
		return {
			value: term.entity_net_terms_id,
			text: term.name,
		};
	});

	const netTermKeyedOptions = _.keyBy(
		props.options,
		(elem) => elem.entity_net_terms_id
	);

	const getNetTerms = (key: string) => {
		return netTermKeyedOptions[key];
	};

	return (
		<EuiFormRow label={'Net Terms'}>
			<EuiSelect
				hasNoInitialSelection
				name={'entityNetTerms'}
				value={props.selectedId}
				onChange={(e) => props.onChange(getNetTerms(e.target.value))}
				options={netTermOptions}
			/>
		</EuiFormRow>
	);
}
