import { EuiTableFieldDataColumnType } from '@equipmentshare/ds2';
import {
	EntityContactXref,
	EntityContact,
} from '@equipmentshare/pcrm-domain/dist/models/index';

export default function (entityId?: number) {
	function render(entities: EntityContactXref[]) {
		return (
			entities.find((entity) => entity.entity_id === entityId)
				?.entity_contact_type?.name ?? ''
		);
	}

	const typeColumn: EuiTableFieldDataColumnType<EntityContact> = {
		field: 'entities',
		name: 'Type',
		render,
		sortable: false,
	};

	return typeColumn;
}
