import { EntityContactXref } from '@equipmentshare/pcrm-domain/dist/models/index';

function render(entities: EntityContactXref[]) {
	const entityIds = entities?.map(
		(entity: EntityContactXref) => entity?.entity?.name
	);

	return entityIds.join(', ');
}

const entitiesColumn = {
	field: 'entities',
	name: 'Vendors',
	render: render,
};

export default entitiesColumn;
