import { useHistory, useLocation, matchPath } from 'react-router-dom';

import { useAuth0 } from '@equipmentshare/auth0-react';
import { EuiSideNavItemType } from '@equipmentshare/ds2';
import {
	User3FillIcon as UserIcon,
	Building4FillIcon as EntitiesIcon,
} from '@equipmentshare/ds2-icons';
import {
	EnvironmentConfiguration,
	UniversalHeaderConsumable,
	createItemClickHandler,
	createNavItem,
} from '@equipmentshare/universal-header';
import { getPromotionLevel, PROD } from './services/promotionLevel';

import './App.css';

// TODO consume HStack from DS when it gets moved
// https://gitlab.internal.equipmentshare.com/track/universal-header/-/blob/master/docs/CONSUMPTION.md

export function getBaseEnvironmentConfiguration(level: string) {
	const environment: 'production' | 'staging' =
		level === PROD ? 'production' : 'staging';

	const baseEnvironmentConfiguration = {
		appId: level === PROD ? 7 : 8, // corresponds with unav_application_id in ESDB unav_applications table
		environment,
	};

	return baseEnvironmentConfiguration;
}

export function Navbar() {
	const auth0 = useAuth0();
	const location = useLocation();
	const history = useHistory();

	const sideNavItems: EuiSideNavItemType<unknown>[] = [
		{
			...createNavItem('entities', 'Vendors', EntitiesIcon),
			...createItemClickHandler('/entities', history.push),
			isSelected: !!matchPath(location.pathname, { path: '/entities' }),
		},
		{
			...createNavItem('contacts', 'Contacts', UserIcon),
			...createItemClickHandler('/entity-contacts', history.push),
			isSelected: !!matchPath(location.pathname, { path: '/entity-contacts' }),
		},
	];

	const environmentConfiguration: EnvironmentConfiguration = {
		...getBaseEnvironmentConfiguration(getPromotionLevel()),
		auth0,
		returnTo: window.location.origin,
		clientId: process.env.REACT_APP_AUTH0_CLIENTID ?? '',
	};

	return (
		<UniversalHeaderConsumable
			environmentConfiguration={environmentConfiguration}
			sideNavItems={sideNavItems}
		/>
	);
}
