import { EuiFormLabel, EuiRadioGroup } from '@equipmentshare/ds2';

type w9OnFileRadioProps = {
	onChange: (value: string) => void;
	formState: string | undefined;
};

export function W9OnFileRadio(props: w9OnFileRadioProps) {
	const radioSelections = [
		{
			id: 'w9Yes',
			label: 'Yes',
			value: 'Yes',
		},
		{
			id: 'w9No',
			label: 'No',
			value: 'No',
		},
	];

	return (
		<EuiRadioGroup
			options={radioSelections}
			// Default selection is "No"
			idSelected={props.formState === 'Yes' ? 'w9Yes' : 'w9No'}
			data-testid={'w9-radio-group'}
			onChange={(_id, name) => (name ? props.onChange(name) : undefined)}
			name="w9OnFile"
			legend={{
				children: <EuiFormLabel>W-9 on File</EuiFormLabel>,
			}}
		/>
	);
}
