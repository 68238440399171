import { ReactElement, useEffect } from 'react';
import {
	Entity,
	EntityType,
	EntityTypeIds,
} from '@equipmentshare/pcrm-domain/dist/models';
import { useForm, ValidationRules } from '../../../hooks/useForm';
import { validateDigits, validateRequired } from '../../../services/validation';
import {
	EuiCallOut,
	EuiFieldText,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiRadio,
	EuiText,
	EuiTitle,
} from '@equipmentshare/ds2';
import Label from 'components/Label';

export type EntityFormState = {
	entityTypeId: string;
	name: string;
	ein2: string;
	ein7: string;
};

type EntityFormProps = {
	displayErrors: boolean;
	entityFormChanged: (entity: EntityFormState) => void;
	entityToEdit?: Entity;
	entityTypes: EntityType[];
	setIsValid: (hasErrors: boolean) => void;
};

export const entityFormValidationRules: ValidationRules<EntityFormState> = {
	name: validateRequired,
	ein2: (ein2, formState) =>
		formState?.entityTypeId &&
		parseInt(formState?.entityTypeId) == EntityTypeIds.BUSINESS
			? validateDigits(ein2, {
					optional: !(formState && formState.ein7),
					length: 2,
			  })
			: '',
	ein7: (ein7, formState) =>
		formState?.entityTypeId &&
		parseInt(formState?.entityTypeId) == EntityTypeIds.BUSINESS
			? validateDigits(ein7, {
					optional: !(formState && formState.ein2),
					length: 7,
			  })
			: '',
};

export function EntityForm(props: EntityFormProps): ReactElement {
	const {
		fields: entityFields,
		handleChange: handleEntityChange,
		errors: entityErrors,
		hasErrors: entityHasErrors,
		setFields: entitySetFields,
	} = useForm<EntityFormState>(
		{
			entityTypeId: '1',
			name: '',
			ein2: '',
			ein7: '',
		},
		{ validationRules: entityFormValidationRules }
	);

	useEffect(() => {
		if (props.entityToEdit) {
			entitySetFields({
				entityTypeId: props.entityToEdit?.entity_type_id.toString() ?? '1',
				name: props.entityToEdit?.name || '',
				ein2: props.entityToEdit?.ein?.toString().substring(0, 2) ?? '',
				ein7: props.entityToEdit?.ein?.toString().substring(2) ?? '',
			});
		}
	}, [props.entityToEdit]);

	useEffect(() => {
		props.setIsValid(!entityHasErrors);
	}, [entityHasErrors]);

	useEffect(() => {
		props.entityFormChanged(entityFields);
	}, [entityFields]);

	return (
		<>
			<EuiFlexItem>
				<EuiTitle size="s">
					<h1>{`${!!props.entityToEdit ? 'Edit' : 'Add'} Vendor`}</h1>
				</EuiTitle>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiCallOut
					title="If a business is a DBA for an individual or partnership, social security number must be entered in external ERP."
					iconType="iInCircle"
					size="s"
				/>
			</EuiFlexItem>
			<EuiFlexItem grow>
				<EuiFormRow label={<Label name="Type" />}>
					<>
						{props.entityTypes.map((type) => (
							<EuiRadio
								id={type.entity_type_id.toString()}
								key={type.entity_type_id.toString()}
								label={type.name}
								data-testid={type.name}
								value={type.entity_type_id.toString()}
								checked={
									entityFields.entityTypeId === type.entity_type_id.toString()
								}
								onChange={handleEntityChange('entityTypeId')}
							/>
						))}
					</>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="legal-name-row"
					label={<Label name="Legal Name" required />}
					isInvalid={props.displayErrors && !!entityErrors.name}
					error={entityErrors.name}
				>
					<EuiFieldText
						data-testid="legal-name-input"
						value={entityFields.name}
						isInvalid={props.displayErrors && !!entityErrors.name}
						onChange={handleEntityChange('name')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			{parseInt(entityFields.entityTypeId) == EntityTypeIds.BUSINESS && (
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" direction="row" responsive={false}>
						<EuiFlexItem grow={false} style={{ width: '65px' }}>
							<EuiFormRow
								data-testid="ein2-row"
								label={<Label name="EIN" />}
								helpText="First two"
								isInvalid={props.displayErrors && !!entityErrors.ein2}
								error={entityErrors.ein2}
							>
								<EuiFieldText
									data-testid="ein2-input"
									maxLength={2}
									value={entityFields.ein2}
									isInvalid={props.displayErrors && !!entityErrors.ein2}
									onChange={handleEntityChange('ein2')}
								/>
							</EuiFormRow>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFormRow
								style={{ marginTop: '25px' }} //needed because the emptyLabelSpace isn't tall enough
								hasEmptyLabelSpace
							>
								<EuiText>-</EuiText>
							</EuiFormRow>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiFormRow
								style={{ marginTop: '25px' }} //needed because the emptyLabelSpace isn't tall enough
								hasEmptyLabelSpace
								data-testid="ein7-row"
								helpText="Last seven"
								isInvalid={props.displayErrors && !!entityErrors.ein7}
								error={entityErrors.ein7}
							>
								<EuiFieldText
									data-testid="ein7-input"
									maxLength={7}
									value={entityFields.ein7}
									isInvalid={props.displayErrors && !!entityErrors.ein7}
									onChange={handleEntityChange('ein7')}
								/>
							</EuiFormRow>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			)}
		</>
	);
}

export default EntityForm;
