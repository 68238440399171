import { ReactElement, useEffect, useMemo } from 'react';
import { useDebounce } from '../../../hooks/useDebounce';
import { useForm, ValidationRules } from '../../../hooks/useForm';
import {
	validateEmail,
	validatePhoneNumber,
	validateRequired,
} from '../../../services/validation';
import { useEntityContacts } from '@equipmentshare/pcrm-domain/dist/queries/entity_contacts';
import { getPurchasesAPIHost } from '../../../services/host';
import { useAuthenticationDetails } from '../../../hooks/useAuthenticationDetails';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import {
	EuiFieldText,
	EuiFlexItem,
	EuiFlexGroup,
	EuiFormRow,
} from '@equipmentshare/ds2';
import Label from 'components/Label';

export type EntityContactFormState = {
	firstName: string;
	lastName: string;
	emailAddress: string;
	phoneNumber: string;
	entityContactId?: number | null;
};

type EntityContactFormProps = {
	displayErrors: boolean;
	entityContactToEdit?: EntityContact;
	entityContactFormChanged: (contact: EntityContactFormState) => void;
	setIsValid: (hasErrors: boolean) => void;
	setKnownContactInfo?: boolean;
};

export const entityContactFormValidationRules: ValidationRules<EntityContactFormState> = {
	firstName: validateRequired,
	lastName: validateRequired,
	emailAddress: (emailAddress) =>
		validateEmail(emailAddress, { optional: true }),
	phoneNumber: (phoneNumber) =>
		validatePhoneNumber(phoneNumber, { optional: true }),
};

export function EntityContactForm(props: EntityContactFormProps): ReactElement {
	const { accessToken } = useAuthenticationDetails();
	const {
		fields: entityContactFields,
		handleChange: handleEntityContactChange,
		errors: entityContactErrors,
		hasErrors: entityContactHasErrors,
		setFields: entityContactSetFields,
	} = useForm<EntityContactFormState>(
		{
			firstName: '',
			lastName: '',
			emailAddress: '',
			phoneNumber: '',
			entityContactId: null,
		},
		{ validationRules: entityContactFormValidationRules }
	);
	const emailSearch = useDebounce(entityContactFields.emailAddress, 200);
	const { data: knownContactData } = useEntityContacts(
		getPurchasesAPIHost(),
		accessToken,
		{
			email_address: emailSearch,
		},
		{
			enabled: !validateEmail(emailSearch),
		}
	);

	const knownContact = useMemo(
		() => knownContactData?.data && knownContactData.data[0],
		[knownContactData]
	);

	useEffect(() => {
		if (props.entityContactToEdit) {
			entityContactSetFields({
				firstName: props.entityContactToEdit.first_name,
				lastName: props.entityContactToEdit.last_name,
				emailAddress: props.entityContactToEdit.email_address ?? '',
				phoneNumber:
					formatPhoneNumber(props.entityContactToEdit.phone_number) ?? '',
				entityContactId: props.entityContactToEdit.entity_contact_id,
			});
		}
	}, [props.entityContactToEdit]);

	useEffect(() => {
		if (knownContact && props.setKnownContactInfo) {
			entityContactSetFields({
				firstName: knownContact.first_name,
				lastName: knownContact.last_name,
				emailAddress: knownContact.email_address || '',
				phoneNumber: formatPhoneNumber(knownContact.phone_number),
				entityContactId: knownContact?.entity_contact_id,
			});
		}
	}, [knownContact]);

	useEffect(() => {
		props.setIsValid(!entityContactHasErrors);
	}, [entityContactHasErrors]);

	useEffect(() => {
		props.entityContactFormChanged(entityContactFields);
	}, [entityContactFields]);

	const isDisabled = useMemo(
		() => !!knownContact && props.setKnownContactInfo,
		[knownContact, props.setKnownContactInfo]
	);

	return (
		<EuiFlexGroup
			id={'FormFlexGroup'}
			direction={'column'}
			gutterSize={'none'}
			justifyContent={'flexStart'}
			component={'span'}
			style={{ flexGrow: 0.2 }}
		>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="first-name-row"
					label={<Label name="First Name" required />}
					isInvalid={
						!knownContact &&
						props.displayErrors &&
						!!entityContactErrors.firstName
					}
					error={entityContactErrors.firstName}
				>
					<EuiFieldText
						data-testid="first-name-input"
						value={entityContactFields.firstName}
						isInvalid={
							!knownContact &&
							props.displayErrors &&
							!!entityContactErrors.firstName
						}
						onChange={handleEntityContactChange('firstName')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="last-name-row"
					label={<Label name="Last Name" required />}
					isInvalid={
						!knownContact &&
						props.displayErrors &&
						!!entityContactErrors.lastName
					}
					error={entityContactErrors.lastName}
				>
					<EuiFieldText
						data-testid="last-name-input"
						value={entityContactFields.lastName}
						isInvalid={
							!knownContact &&
							props.displayErrors &&
							!!entityContactErrors.lastName
						}
						onChange={handleEntityContactChange('lastName')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="email-address-row"
					label={<Label name="Email Address" />}
					helpText={
						knownContact &&
						props.setKnownContactInfo &&
						'Contact already exists and will be associated with this entity'
					}
					isInvalid={
						entityContactErrors.emailAddress !== 'Contact already exists' &&
						props.displayErrors &&
						!!entityContactErrors.emailAddress
					}
					error={entityContactErrors.emailAddress}
				>
					<EuiFieldText
						data-testid="email-address-input"
						type={'email'}
						value={entityContactFields.emailAddress}
						isInvalid={
							entityContactErrors.emailAddress !== 'Contact already exists' &&
							props.displayErrors &&
							!!entityContactErrors.emailAddress
						}
						onChange={handleEntityContactChange('emailAddress')}
					/>
				</EuiFormRow>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiFormRow
					data-testid="phone-number-row"
					label={<Label name="Phone Number" />}
					isDisabled={isDisabled && !!knownContact?.phone_number}
					isInvalid={
						!knownContact &&
						props.displayErrors &&
						!!entityContactErrors.phoneNumber
					}
					error={entityContactErrors.phoneNumber}
				>
					<EuiFieldText
						data-testid="phone-number-input"
						type={'tel'}
						value={entityContactFields.phoneNumber}
						isInvalid={
							!knownContact &&
							props.displayErrors &&
							!!entityContactErrors.phoneNumber
						}
						onChange={handleEntityContactChange('phoneNumber', (e) =>
							formatPhoneNumber(e.target.value)
						)}
					/>
				</EuiFormRow>
			</EuiFlexItem>
		</EuiFlexGroup>
	);
}

export default EntityContactForm;
