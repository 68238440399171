import { useEntityLookupValues } from '@equipmentshare/pcrm-domain/dist/queries/entity_lookup_values';
import { upsertEntityVendorSettingsMutation } from '@equipmentshare/pcrm-domain/dist/mutations/entity_vendor_settings';
import { useEffect, useState } from 'react';
import { useAuthenticationDetails } from '../../../../hooks/useAuthenticationDetails';
import { useForm } from '../../../../hooks/useForm';
import { getPurchasesAPIHost } from '../../../../services/host';
import EntityVendorSettingsForm, {
	EntityVendorSettingsFormState,
	entityVendorSettingsFormValidationRules,
} from '../../../EntityVendorSettings/EntityVendorSettingsForm';
import { Entity } from '@equipmentshare/pcrm-domain/dist/models';
import './VendorSettingsFormContainer.css';
import { EuiButton, EuiText } from '@equipmentshare/ds2';

type VendorSettingsFormContainer = {
	entity?: Entity;
	onClose: () => void;
};

type FieldsChangedTuple = [
	string,
	string | number | undefined | null,
	string | number | undefined | null
];

export function VendorSettingsFormContainer(
	props: VendorSettingsFormContainer
) {
	const { accessToken } = useAuthenticationDetails();
	const { data: lookupValues } = useEntityLookupValues(
		getPurchasesAPIHost(),
		accessToken
	);

	const [displayErrors, setDisplayErrors] = useState(false);

	const { mutate, isLoading, status } = upsertEntityVendorSettingsMutation(
		getPurchasesAPIHost(),
		accessToken
	);

	useEffect(() => {
		if (status === 'success') {
			props.onClose();
		}
	}, [status]);

	function handleSubmit() {
		if (!hasErrors && props.entity?.entity_id) {
			if (props.entity?.entity_vendor_settings?.entity_vendor_settings_id) {
				const fieldsChangedTuples: FieldsChangedTuple[] = [
					[
						'entity_net_terms_id',
						fields.entityNetTerms?.entity_net_terms_id,
						props.entity.entity_vendor_settings.entity_net_terms_id,
					],
					[
						'credit_limit',
						parseFloat(fields.creditLimit.replace(/\D/g, '')),
						props.entity.entity_vendor_settings?.credit_limit,
					],
					[
						'external_erp_vendor_ref',
						fields.externalErpVendorRef,
						props.entity.entity_vendor_settings?.external_erp_vendor_ref,
					],
					[
						'w9_on_file',
						fields.w9OnFile === 'No' ? '' : fields.w9OnFile,
						props.entity.entity_vendor_settings.w9_on_file,
					],
					['notes', fields.notes, props.entity.entity_vendor_settings.notes],
				];

				const fieldsChanged = fieldsChangedTuples.reduce(
					(acc, [key, newValue, oldValue]) =>
						newValue === oldValue
							? acc
							: {
									...acc,
									[key]: newValue,
							  },
					{}
				);

				if (!!Object.keys(fieldsChanged).length) {
					mutate({
						entity_vendor_settings_id:
							props.entity?.entity_vendor_settings?.entity_vendor_settings_id,
						...fieldsChanged,
					});
				} else {
					props.onClose();
				}
			} else {
				mutate({
					entity_id: props.entity?.entity_id,
					entity_net_terms_id: fields.entityNetTerms?.entity_net_terms_id,
					credit_limit: parseFloat(fields.creditLimit.replace(/\D/g, '')),
					external_erp_vendor_ref: fields.externalErpVendorRef,
					w9_on_file: fields.w9OnFile === 'No' ? '' : fields.w9OnFile,
					notes: fields.notes,
				});
			}
		} else {
			setDisplayErrors(true);
		}
	}

	const {
		fields,
		handleChange,
		errors,
		hasErrors,
	} = useForm<EntityVendorSettingsFormState>(
		{
			entityNetTerms: props.entity?.entity_vendor_settings?.entity_net_term,
			creditLimit:
				props.entity?.entity_vendor_settings?.credit_limit?.toString() ?? '',
			externalErpVendorRef:
				props.entity?.entity_vendor_settings?.external_erp_vendor_ref ?? '',
			notes: props.entity?.entity_vendor_settings?.notes ?? '',
			w9OnFile: props.entity?.entity_vendor_settings?.w9_on_file ?? '',
		},
		{ validationRules: entityVendorSettingsFormValidationRules }
	);

	if (!lookupValues) {
		return <EuiText>Loading...</EuiText>;
	}

	return (
		<>
			<EntityVendorSettingsForm
				formState={fields}
				entityNetTerms={lookupValues.entity_net_terms}
				onChange={handleChange}
				errors={displayErrors ? errors : {}}
			/>
			<div className="ActionButtons">
				<EuiButton color={'text'} title="Cancel" onClick={props.onClose}>
					Cancel
				</EuiButton>

				<EuiButton
					color={'primary'}
					fill={true}
					isDisabled={isLoading}
					onClick={handleSubmit}
					data-testid={'vendor-submit-button'}
				>
					Save
				</EuiButton>
			</div>
		</>
	);
}

export default VendorSettingsFormContainer;
