import { useEffect, useState } from 'react';

export function useDebounce<Value>(value: Value, millisecondDelay: number) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, millisecondDelay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, millisecondDelay]);

	return debouncedValue;
}
