import { ReactElement, useState } from 'react';
import { useEntityContacts } from '@equipmentshare/pcrm-domain/dist/queries/entity_contacts';
import { getPurchasesAPIHost } from '../../services/host';
import { useAuthenticationDetails } from '../../hooks/useAuthenticationDetails';
import ContactsTable from '../../components/Tables/ContactsTable/ContactsTable';
import './contacts.css';
import '../screens.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import {
	Criteria,
	Direction,
	EuiPage,
	EuiPageBody,
	EuiPageContent,
	EuiSpacer,
	EuiTableSortingType,
} from '@equipmentshare/ds2';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import ContactTableFilters, {
	ContactFilters,
} from 'components/Tables/ContactTableFilters/ContactTableFilters';

export function Contacts(): ReactElement {
	const [filters, setFilters] = useState<ContactFilters>({ search: undefined });
	const [sortField, setSortField] = useState<keyof EntityContact>('first_name');
	const [sortDirection, setSortDirection] = useState<Direction>('asc');
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const authDetails = useAuthenticationDetails();
	const {
		data: paginatedContacts,
		isLoading: contactsDataIsLoading,
	} = useEntityContacts(getPurchasesAPIHost(), authDetails.accessToken, {
		search: filters.search,
		limit: pageSize,
		offset: pageIndex * pageSize,
		sort_col: sortField,
		sort_dir: sortDirection,
	});

	const onUpdateFilters = (filters: ContactFilters) => {
		setFilters(filters);
		setPageIndex(0);
	};

	const sorting: EuiTableSortingType<EntityContact> = {
		sort: {
			field: sortField,
			direction: sortDirection,
		},
	};

	const pagination = {
		pageIndex,
		pageSize,
		totalItemCount: paginatedContacts?.total ?? 0,
		pageSizeOptions: [10, 20],
		showPerPageOptions: true,
	};

	const onTableChange = ({
		sort = { field: 'entity_contact_id', direction: 'desc' },
		page = { index: 0, size: 10 },
	}: Criteria<EntityContact>) => {
		const { index: pageIndex, size: pageSize } = page;
		const { field: sortField, direction: sortDirection } = sort;

		setPageIndex(pageIndex);
		setPageSize(pageSize);
		setSortField(sortField);
		setSortDirection(sortDirection);
	};

	return (
		<EuiPage direction="column">
			<PageHeader
				buttonLink="/entity-contacts/add"
				buttonTitle="Add Contact"
				headerTitle="Contacts"
			/>
			<EuiPageBody>
				<EuiPageContent
					hasBorder={false}
					hasShadow={false}
					paddingSize="none"
					color="transparent"
					borderRadius="none"
				>
					<EuiSpacer />
					<ContactTableFilters onChange={onUpdateFilters} />
					<EuiSpacer size="m" />
					<ContactsTable
						loading={contactsDataIsLoading}
						data={paginatedContacts?.data}
						sorting={sorting}
						pagination={pagination}
						onChange={onTableChange}
					/>
				</EuiPageContent>
			</EuiPageBody>
		</EuiPage>
	);
}

export default Contacts;
