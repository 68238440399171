import { EntityNetTerms } from '@equipmentshare/pcrm-domain/dist/models';

function render(netTerm: EntityNetTerms | undefined) {
	return netTerm?.name || '';
}

export default {
	field: 'entity_vendor_settings.entity_net_term.name',
	name: 'Net Terms',
	render: render,
	sortable: false,
};
