import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';

function render(primaryContact: EntityContact) {
	const firstName = primaryContact?.first_name || '';
	const lastName = primaryContact?.last_name || '';
	return `${firstName} ${lastName}`;
}

export default {
	field: 'primary_contact',
	name: 'Contact',
	render: render,
	sortable: false,
};
