import idColumn from './id';
import billingAddressColumn from './billingAddress';
import contactColumn from './contact';
import erpColumn from './erp';
import nameColumn from './name';
import netTermsColumn from './netTerms';

export default [
	idColumn,
	nameColumn,
	erpColumn,
	billingAddressColumn,
	contactColumn,
	netTermsColumn,
];
