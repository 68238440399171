import { EuiIcon } from '@equipmentshare/ds2';

function render(erp: string) {
	return erp ? <EuiIcon color="success" type="checkInCircleFilled" /> : '';
}

export default {
	field: 'entity_vendor_settings.external_erp_vendor_ref',
	name: 'ERP',
	render: render,
	sortable: false,
	width: '80px',
};
