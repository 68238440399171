import { Entity } from '@equipmentshare/pcrm-domain/dist/models';
import columns from './columns';
import {
	CriteriaWithPagination,
	Direction,
	EuiBasicTable,
	EuiSpacer,
	EuiTableFieldDataColumnType,
	EuiTableSortingType,
} from '@equipmentshare/ds2';
import { ReactElement, useState } from 'react';
import EntityTableFilters from '../EntityTableFilters/EntityTableFilters';
import {
	EntitiesVariables,
	useEntities,
} from '@equipmentshare/pcrm-domain/dist/queries/entities';
import { useAuthenticationDetails } from '../../../hooks/useAuthenticationDetails';
import { getPurchasesAPIHost } from '../../../services/host';

type EntitiesFilters = Pick<EntitiesVariables, 'is_vendor' | 'is_customer'>;

export default function VendorsTable(): ReactElement {
	const defaultEntitiesVariables: EntitiesVariables = {
		include_inactive: true,
	};
	const [filters, setFilters] = useState<EntitiesFilters>({});
	const [sortField, setSortField] = useState<keyof Entity>('entity_id');
	const [sortDirection, setSortDirection] = useState<Direction>('desc');
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const authDetails = useAuthenticationDetails();
	const { data: entities, isLoading: entitiesDataIsLoading } = useEntities(
		getPurchasesAPIHost(),
		authDetails.accessToken,
		{
			...defaultEntitiesVariables,
			...{
				limit: pageSize,
				offset: pageIndex * pageSize,
				sort_col: sortField,
				sort_dir: sortDirection,
			},
			...filters,
		}
	);

	const getCellProps = (
		entity: Entity,
		column: EuiTableFieldDataColumnType<EuiBasicTable>
	) => {
		const { field } = column;
		return {
			className:
				!entity.active && field !== 'name' ? 'inactive-entity-opacity' : '',
		};
	};

	const sorting: EuiTableSortingType<Entity> = {
		sort: {
			field: sortField,
			direction: sortDirection,
		},
	};

	const pagination = {
		pageIndex,
		pageSize,
		totalItemCount: entities?.total || 0,
		pageSizeOptions: [10, 20],
		showPerPageOptions: true,
	};

	const onTableChange = ({
		sort = { field: 'entity_id', direction: 'asc' },
		page = { index: 0, size: 10 },
	}: CriteriaWithPagination<Entity>) => {
		const { index: pageIndex, size: pageSize } = page;
		const { field: sortField, direction: sortDirection } = sort;

		setPageIndex(pageIndex);
		setPageSize(pageSize);
		setSortField(sortField);
		setSortDirection(sortDirection);
	};
	return (
		<>
			<EntityTableFilters onChange={setFilters} />
			<EuiSpacer size="m" />
			<EuiBasicTable
				cellProps={getCellProps}
				columns={columns}
				items={entities?.data || []}
				loading={entitiesDataIsLoading}
				noItemsMessage="No Vendors"
				onChange={onTableChange}
				pagination={pagination}
				rowHeader="entity_id"
				sorting={sorting}
			/>
		</>
	);
}
