import React, { useEffect, useMemo, useState } from 'react';

type Extractor<FormState, Key extends keyof FormState, EventType> = (
	e: EventType
) => FormState[Key];

export type ChangeHandler<FormState> = {
	<
		EventType extends React.ChangeEvent<HTMLInputElement>,
		Key extends keyof FormState
	>(
		key: Key
	): (e: EventType) => void;
	<EventType, Key extends keyof FormState>(
		key: Key,
		extractor: Extractor<FormState, Key, EventType>
	): (e: EventType) => void;
};

export type ValidationRules<FormState> = {
	[key in keyof FormState]?: (
		value: FormState[key],
		formState?: FormState
	) => string;
};

export type FormErrors<FormState> = { [key in keyof FormState]?: string };

type Options<FormState> = {
	validationRules?: ValidationRules<FormState>;
};

export function useForm<FormState>(
	initialValues: FormState,
	options?: Options<FormState>
) {
	const [fields, setFields] = useState<FormState>(initialValues);
	const [errors, setErrors] = useState<FormErrors<FormState>>({});

	useEffect(() => {
		const errors: FormErrors<FormState> = {};

		for (const field in fields) {
			if (options?.validationRules) {
				const rule = options.validationRules[field];

				if (rule) {
					errors[field] = rule(fields[field], fields);
				}
			}
		}

		setErrors(errors);
	}, [fields]);

	const handleChange: ChangeHandler<FormState> = <
		EventType extends Partial<React.ChangeEvent<HTMLInputElement>>,
		Key extends keyof FormState
	>(
		key: Key,
		extractor?: Extractor<FormState, Key, EventType>
	) => {
		return (e: EventType) => {
			const value = extractor
				? extractor(e)
				: (e as React.ChangeEvent<HTMLInputElement>).target.value;
			setFields((prev) => ({
				...prev,
				[key]: value,
			}));
		};
	};

	const hasErrors = useMemo(
		() => !!Object.values(errors).find((error) => error !== ''),
		[errors]
	);

	return { fields, handleChange, errors, hasErrors, setFields };
}
