import {
	EntityContact,
	EntityTypeIds,
} from '@equipmentshare/pcrm-domain/dist/models';
import {
	createEntityWithPrimaryContactAndBusinessAddressMutation,
	updateEntityWithPrimaryContactAndBusinessAddressMutation,
} from '@equipmentshare/pcrm-domain/dist/mutations/entities';
import { useEntity } from '@equipmentshare/pcrm-domain/dist/queries/entity';
import { useEntityContacts } from '@equipmentshare/pcrm-domain/dist/queries/entity_contacts';
import { useEntityLookupValues } from '@equipmentshare/pcrm-domain/dist/queries/entity_lookup_values';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAuthenticationDetails } from '../../../hooks/useAuthenticationDetails';
import { EntityRouteParams } from '../../vendor_details/vendorDetails';
import { getPurchasesAPIHost } from '../../../services/host';
import EntityLocationForm, {
	EntityLocationFormState,
} from '../../../components/EntityLocation/EntityLocationForm';
import { useQueryClient } from 'react-query';
import PrimaryContactFormContainer from '../../../components/PrimaryContactFormContainer/PrimaryContactFormContainer';
import { EntityContactFormState } from '../../../components/EntityContact/EntityContactForm/EntityContactForm';
import EntityForm, {
	EntityFormState,
} from '../../../components/Entity/EntityForm/EntityForm';
import {
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPage,
	EuiText,
} from '@equipmentshare/ds2';

export function EntityFormScreen(): ReactElement {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { id } = useParams<EntityRouteParams>();
	const location = useLocation();
	const { accessToken } = useAuthenticationDetails();
	const { data: lookupValues } = useEntityLookupValues(
		getPurchasesAPIHost(),
		accessToken
	);

	const [displayErrors, setDisplayErrors] = useState(false);
	const [entityIsValid, setEntityIsValid] = useState(true);
	const [entityContactIsValid, setEntityContactIsValid] = useState(true);
	const [entityLocationIsValid, setEntityLocationIsValid] = useState(true);
	const [
		selectedPrimaryContact,
		setSelectedPrimaryContact,
	] = useState<EntityContact | null>(null);
	const [entityForm, setEntityForm] = useState<EntityFormState | undefined>();
	const [entityLocationForm, setEntityLocationForm] = useState<
		EntityLocationFormState | undefined
	>();
	const [primaryContactForm, setPrimaryContactForm] = useState<
		EntityContactFormState | undefined
	>();
	const { data: entityToUpdate } = useEntity(
		getPurchasesAPIHost(),
		accessToken,
		id
	);
	const { data: contactsData } = useEntityContacts(
		getPurchasesAPIHost(),
		accessToken
	);
	const {
		mutate: createMutate,
		status: createStatus,
		data: createData,
		isLoading: createIsLoading,
	} = createEntityWithPrimaryContactAndBusinessAddressMutation(
		getPurchasesAPIHost(),
		accessToken
	);

	const {
		mutate: updateMutate,
		status: updateStatus,
		isLoading: updateIsLoading,
		data: updateData,
	} = updateEntityWithPrimaryContactAndBusinessAddressMutation(
		getPurchasesAPIHost(),
		accessToken
	);

	function getEntity(entityForm: EntityFormState) {
		const entityTypeId = parseInt(entityForm.entityTypeId);
		return {
			entity_type_id: entityTypeId,
			ein:
				entityTypeId == EntityTypeIds.BUSINESS
					? parseInt(entityForm.ein2 + entityForm.ein7)
					: null,
			name: entityForm.name,
		};
	}

	function getBusinessAddress(entityLocationFields: EntityLocationFormState) {
		return {
			street_1: entityLocationFields.street1,
			street_2: entityLocationFields.street2,
			city: entityLocationFields.city,
			entity_state_id: entityLocationFields.state?.entity_state_id || null,
			zip_code: entityLocationFields.zipCode,
		};
	}

	function getPrimaryContact(primaryContact?: EntityContactFormState) {
		const strippedPhoneNumber = primaryContact?.phoneNumber.replace(
			/[^\d]/g,
			''
		);
		return (
			(selectedPrimaryContact && selectedPrimaryContact.entity_contact_id) ?? {
				first_name: primaryContact?.firstName ?? '',
				last_name: primaryContact?.lastName ?? '',
				email_address: primaryContact?.emailAddress || null,
				phone_number: strippedPhoneNumber ?? '',
			}
		);
	}

	function handleCreate() {
		if (
			entityIsValid &&
			entityForm &&
			entityContactIsValid &&
			entityLocationIsValid &&
			entityLocationForm?.state
		) {
			createMutate({
				entity: getEntity(entityForm),
				businessAddress: getBusinessAddress(entityLocationForm),
				primaryContact: getPrimaryContact(primaryContactForm),
			});
		} else {
			setDisplayErrors(true);
		}
	}

	function handleUpdate() {
		if (
			entityIsValid &&
			entityForm &&
			entityContactIsValid &&
			entityLocationIsValid &&
			entityLocationForm?.state &&
			entityToUpdate
		) {
			updateMutate({
				oldData: {
					entity: entityToUpdate,
				},
				newData: {
					entity: getEntity(entityForm),
					businessAddress: getBusinessAddress(entityLocationForm),
					primaryContact: getPrimaryContact(primaryContactForm),
				},
			});
		} else {
			setDisplayErrors(true);
		}
	}

	useEffect(() => {
		if (entityToUpdate) {
			queryClient.invalidateQueries([
				`getEntity/entityId:${entityToUpdate.entity_id}`,
				getPurchasesAPIHost(),
				accessToken,
			]);
		}
	}, []);

	useEffect(() => {
		if (createStatus === 'success') {
			history.push(`/entities/${createData?.entity_id}`);
		}
	}, [createStatus]);

	useEffect(() => {
		if (updateStatus === 'success') {
			history.push(`/entities/${updateData?.entity_id}`);
		}
	}, [updateStatus]);

	useEffect(() => {
		if (entityToUpdate) {
			const primaryContact = entityToUpdate?.primary_contact;
			primaryContact && setSelectedPrimaryContact(primaryContact);
		}
	}, [entityToUpdate]);

	if (!lookupValues) {
		return <EuiText>Loading...</EuiText>;
	}

	return (
		<EuiPage direction="column">
			<EuiFlexGroup direction="column" gutterSize="m">
				<EntityForm
					displayErrors={displayErrors}
					entityFormChanged={setEntityForm}
					entityToEdit={entityToUpdate}
					entityTypes={lookupValues.entity_types}
					setIsValid={setEntityIsValid}
				/>
				<EntityLocationForm
					displayErrors={displayErrors}
					entityLocationFormChanged={setEntityLocationForm}
					entityLocationToEdit={entityToUpdate?.business_address}
					entityStates={lookupValues.entity_states}
					setIsValid={setEntityLocationIsValid}
				/>
				<PrimaryContactFormContainer
					contacts={contactsData?.data || []}
					displayErrors={displayErrors}
					primaryContactFormChanged={setPrimaryContactForm}
					primaryContactValueChanged={setSelectedPrimaryContact}
					selectedPrimaryContact={selectedPrimaryContact}
					setIsValid={setEntityContactIsValid}
					setKnownContactInfo={!location.pathname.includes('edit')}
				/>
				<EuiFlexItem grow={false}>
					<EuiFlexGroup direction="row" gutterSize="m">
						<EuiFlexItem grow={false}>
							<EuiButton
								color="text"
								onClick={
									!!entityToUpdate
										? () => history.push(`/entities/${id}`)
										: () => history.push('/entities')
								}
								isDisabled={createIsLoading || updateIsLoading}
								data-testid="cancel"
							>
								Cancel
							</EuiButton>
						</EuiFlexItem>
						<EuiFlexItem grow={false}>
							<EuiButton
								color="primary"
								fill
								isLoading={createIsLoading || updateIsLoading}
								onClick={
									location.pathname.includes('edit')
										? handleUpdate
										: handleCreate
								}
								isDisabled={createIsLoading || updateIsLoading}
								data-testid="submit"
							>
								{location.pathname.includes('edit') ? 'Update' : 'Submit'}
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiPage>
	);
}

export default EntityForm;
