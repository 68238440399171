import { EuiTableFieldDataColumnType } from '@equipmentshare/ds2';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

const phoneNumberColumn: EuiTableFieldDataColumnType<EntityContact> = {
	field: 'phone_number',
	name: 'Phone',
	render: (phoneNumber: EntityContact['phone_number']) =>
		formatPhoneNumber(phoneNumber),
	sortable: false,
};

export default phoneNumberColumn;
