import { EuiTitle, EuiTitleProps } from '@equipmentshare/ds2';
import { ReactElement } from 'react';

type TitleProps = {
	title: string;
} & Pick<EuiTitleProps, 'size'>;

export function Title(props: TitleProps): ReactElement {
	return (
		<EuiTitle size={props.size} className={'Title'}>
			<h1>
				<strong>{props.title}</strong>
			</h1>
		</EuiTitle>
	);
}
