import {
	Entity,
	EntityContact,
	EntityContactTypeIds,
} from '@equipmentshare/pcrm-domain/dist/models';
import {
	EuiDescriptionList,
	EuiEmptyPrompt,
	EuiTitle,
} from '@equipmentshare/ds2';
import { ReactElement, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getContactByTypeId } from '../../../services/getContactByTypeId';
import { convertToUSD } from '../../../utils/convertToUSD';
import './VendorSettings.css';
import { SettingHeader } from './SettingsHeader/SettingsHeader';
import { VendorSettingsFormContainer } from './VendorSettingsFormContainer/VendorSettingsFormContainer';
import { useAuthenticationDetails } from 'hooks/useAuthenticationDetails';
import { useIsFeatureCompany } from 'hooks/useIsFeatureCompany';

type VendorSettingsProps = {
	vendor?: Entity;
	contacts?: EntityContact[];
};

export function VendorSettings(props: VendorSettingsProps): ReactElement {
	const { vendor, contacts } = props;
	const { user } = useAuthenticationDetails();
	const isFeatureCompany = useIsFeatureCompany(user);
	const apContact = useMemo(
		() =>
			contacts &&
			contacts[0] &&
			vendor &&
			getContactByTypeId(
				contacts,
				vendor.entity_id,
				EntityContactTypeIds.AP_CONTACT
			),
		[contacts, vendor]
	);
	const [settingsFormActive, setSettingsFormActive] = useState(false);
	const history = useHistory();

	const listItems = [
		{
			title: 'AP Contact',
			description: apContact
				? `${apContact.first_name} ${apContact.last_name}`
				: '-',
			testId: 'ap-contact',
		},
		{
			title: 'Terms',
			description: vendor?.entity_vendor_settings?.entity_net_term?.name,
			testId: 'terms',
		},
		{
			title: 'Credit Limit',
			description:
				typeof vendor?.entity_vendor_settings?.credit_limit === 'number'
					? convertToUSD(vendor?.entity_vendor_settings?.credit_limit)
					: undefined,
			testId: 'credit-limit',
		},
		{
			title: 'Vendor Notes',
			description: vendor?.entity_vendor_settings?.notes || '',
			testId: 'notes',
		},
		{
			title: 'Has W-9',
			description: vendor?.entity_vendor_settings?.w9_on_file ? 'Yes' : 'No',
			testId: 'w9-on-file',
		},
	].filter((item) => isFeatureCompany || item.title !== 'AP Contact');

	const formattedListItems = listItems.map((item) => {
		return {
			title: <span>{item.title}</span>,
			description: <span data-testid={item.testId}>{item.description}</span>,
		};
	});

	return (
		<div>
			<SettingHeader
				onClick={() => setSettingsFormActive(true)}
				title={'Settings'}
				testId={'vendor-settings-header'}
				editFormActive={settingsFormActive}
			/>
			<div className="VendorSettingsBody">
				{settingsFormActive ? (
					<VendorSettingsFormContainer
						entity={props.vendor}
						onClose={() => setSettingsFormActive(false)}
					/>
				) : (
					<EuiDescriptionList
						listItems={formattedListItems}
						type={'column'}
						align={'left'}
						textStyle={'reverse'}
						className={'VendorDescriptionList'}
					/>
				)}
			</div>
			{isFeatureCompany && (
				<>
					<SettingHeader
						onClick={() =>
							/* form disabled: there is no support for Products & Services */
							history.push(
								`/product-services-settings-edit/${vendor?.entity_id}`
							)
						}
						formDisabled={true}
						title={'Products & Services'}
						testId={'products-edit-button'}
					/>
					<EuiEmptyPrompt
						titleSize={'s'}
						title={
							<EuiTitle>
								<h2>No products or services</h2>
							</EuiTitle>
						}
					/>
				</>
			)}
		</div>
	);
}

export default VendorSettings;
