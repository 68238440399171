import { EntityLocation } from '@equipmentshare/pcrm-domain/dist/models';
import { formatAddress } from '../../../../utils/formatAddress';

function render(businessAddress: EntityLocation) {
	return businessAddress ? formatAddress(businessAddress) : '';
}

export default {
	field: 'business_address',
	name: 'Billing Address',
	render: render,
	sortable: false,
	width: '30%',
};
