import {
	EntityContact,
	EntityContactXref,
} from '@equipmentshare/pcrm-domain/dist/models';

export function getContactByTypeId(
	contacts: EntityContact[],
	entityId: number,
	typeId: number
): EntityContact | undefined {
	return contacts.find((contact) =>
		contact.entities.find(
			(entity: EntityContactXref) =>
				entity.entity_contact_type_id === typeId &&
				entity.entity_id === entityId
		)
	);
}
