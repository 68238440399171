import './ErrorMessage.css';
import { EuiEmptyPrompt, EuiIcon } from '@equipmentshare/ds2';

type AlertProps = {
	children: React.ReactNode;
	className?: string;
};

export const ErrorMessage = (props: AlertProps) => {
	return (
		<EuiEmptyPrompt
			className={props.className}
			// iconType={"alert"}
			color={'danger'}
			icon={<EuiIcon type={'alert'} color={'danger'} title={'Error Icon'} />}
			title={<h2>Error</h2>}
			body={props.children}
			layout={'horizontal'}
			paddingSize={'s'}
		/>
	);
};
