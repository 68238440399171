import { ReactElement, useState } from 'react';
import {
	EuiButton,
	EuiFieldSearch,
	EuiFilterButton,
	EuiFilterGroup,
	EuiFlexGroup,
	EuiFlexItem,
	EuiPopover,
	EuiSelectable,
} from '@equipmentshare/ds2';
import { EuiSelectableOption } from '@elastic/eui/src/components/selectable/selectable_option';
import { EntitiesVariables } from '@equipmentshare/pcrm-domain/dist/queries/entities';
import { useAuthenticationDetails } from 'hooks/useAuthenticationDetails';
import { useIsFeatureCompany } from 'hooks/useIsFeatureCompany';

type EntitiesFilters = Pick<
	EntitiesVariables,
	'is_vendor' | 'is_customer' | 'search'
>;

type EntityTableFiltersProps = {
	onChange: (entitiesFilters: {
		is_customer?: boolean;
		is_vendor?: boolean;
	}) => void;
};

export default function EntityTableFilters(
	props: EntityTableFiltersProps
): ReactElement {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { user } = useAuthenticationDetails();
	const isFeatureCompany = useIsFeatureCompany(user);
	const [options, setOptions] = useState<EuiSelectableOption[]>([
		{
			key: 'is_vendor',
			label: 'Vendor',
		},
		{
			key: 'is_customer',
			label: 'Customer',
		},
	]);
	const [search, setSearch] = useState('');

	const onButtonClick = () => {
		setIsPopoverOpen(!isPopoverOpen);
	};

	const closePopover = () => {
		setIsPopoverOpen(false);
	};

	const getTypeFilters = (options: EuiSelectableOption[]) => {
		const typeFilters: Partial<EntitiesFilters> = {};
		options.forEach((option) => {
			if (option.checked === 'on') {
				if (option.key === 'is_vendor') {
					typeFilters.is_vendor = true;
				}
				if (option.key === 'is_customer') {
					typeFilters.is_customer = true;
				}
			}
		});
		return typeFilters;
	};

	const getSearchFilter = (search: string | undefined) => {
		const searchFilter: Partial<EntitiesFilters> = {};
		if (search) {
			searchFilter.search = search;
		}
		return searchFilter;
	};

	const onChange = (search: string, options: EuiSelectableOption[]) => {
		props.onChange({
			...getSearchFilter(search),
			...getTypeFilters(options),
		});
	};

	const onTypeChange = (newOptions: EuiSelectableOption[]) => {
		setOptions(newOptions);
		onChange(search, newOptions);
	};

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const search = e.currentTarget.value;
		setSearch(search);
	};

	const onSearch = (search: string) => {
		setSearch(search);
		onChange(search, options);
	};

	return (
		<EuiFlexGroup>
			<EuiFlexItem grow={false}>
				<EuiFlexGroup gutterSize={'none'}>
					<EuiFieldSearch
						isClearable={true}
						onChange={onSearchChange}
						onSearch={onSearch}
						placeholder="Search by Name or ID"
						value={search}
						style={{ width: '352px', marginRight: '8px' }}
					/>
					<EuiButton onClick={() => onSearch(search)} color="text">
						Search
					</EuiButton>
				</EuiFlexGroup>
			</EuiFlexItem>
			{isFeatureCompany && (
				<EuiFlexItem grow={false}>
					<EuiFilterGroup>
						<EuiPopover
							button={
								<EuiFilterButton
									iconType="arrowDown"
									onClick={onButtonClick}
									isSelected={isPopoverOpen}
									numFilters={options.length}
									hasActiveFilters={options.some(
										(item) => item.checked === 'on'
									)}
									numActiveFilters={
										options.filter((item) => item.checked === 'on').length
									}
									// Needed to make colors consistent with other T3 apps
									style={{
										backgroundColor: '#fafbfc',
										color: '#626f7e',
									}}
								>
									Type of entity
								</EuiFilterButton>
							}
							isOpen={isPopoverOpen}
							closePopover={closePopover}
							panelPaddingSize="none"
						>
							<EuiSelectable
								options={options}
								onChange={(newOptions) => onTypeChange(newOptions)}
								singleSelection={true}
							>
								{(list) => <div style={{ width: 180 }}>{list}</div>}
							</EuiSelectable>
						</EuiPopover>
					</EuiFilterGroup>
				</EuiFlexItem>
			)}
		</EuiFlexGroup>
	);
}
