import { ReactElement } from 'react';
import {
	ChangeHandler,
	FormErrors,
	ValidationRules,
} from '../../hooks/useForm';
import { validateNumber } from '../../services/validation';
import { EntityNetTerms } from '@equipmentshare/pcrm-domain/dist/models';
import {
	EuiFieldText,
	EuiForm,
	EuiFormRow,
	EuiSpacer,
} from '@equipmentshare/ds2';
import { EntityNetTermsDropdown } from './EntityNetTermsDropdown';
import { W9OnFileRadio } from './W9OnFileDropdown';
import { identity } from 'lodash';

export type EntityVendorSettingsFormState = {
	entityNetTerms?: EntityNetTerms | null;
	creditLimit: string;
	externalErpVendorRef: string;
	w9OnFile: string;
	notes: string;
};

type EntityVendorSettingsFormProps = {
	errors: FormErrors<EntityVendorSettingsFormState>;
	formState: EntityVendorSettingsFormState;
	entityNetTerms: EntityNetTerms[];
	onChange: ChangeHandler<EntityVendorSettingsFormState>;
};

export const entityVendorSettingsFormValidationRules: ValidationRules<EntityVendorSettingsFormState> = {
	creditLimit: (value) => validateNumber(value, { optional: true }),
};

export function EntityVendorSettingsForm(
	props: EntityVendorSettingsFormProps
): ReactElement {
	const handleCreditLimitChange = props.onChange('creditLimit', identity);

	const handleExternalIdChange = props.onChange(
		'externalErpVendorRef',
		identity
	);

	const handleNotesChange = props.onChange('notes', identity);

	return (
		<EuiForm>
			<EuiSpacer size={'s'} />
			<EntityNetTermsDropdown
				onChange={props.onChange(
					'entityNetTerms',
					(entityNetTerms: EntityNetTerms) => entityNetTerms
				)}
				options={props.entityNetTerms}
				selectedId={props.formState.entityNetTerms?.entity_net_terms_id}
			/>

			<EuiFormRow
				label={'Credit Limit'}
				isInvalid={Boolean(props.errors.creditLimit)}
				error={props.errors.creditLimit}
				data-testid={'credit-limit-wrapper'}
			>
				<EuiFieldText
					data-testid={'credit-limit'}
					name={'creditLimit'}
					defaultValue={props.formState.creditLimit}
					onChange={(e) => handleCreditLimitChange(e.target.value)}
				/>
			</EuiFormRow>

			<EuiFormRow label={'External ID'}>
				<EuiFieldText
					data-testid={'external-id'}
					name={'externalErpVendorRef'}
					defaultValue={props.formState.externalErpVendorRef}
					onChange={(e) => handleExternalIdChange(e.target.value)}
				/>
			</EuiFormRow>

			<EuiFormRow label={'Vendor Notes'}>
				<EuiFieldText
					data-testid={'vendor-notes'}
					name={'notes'}
					defaultValue={props.formState.notes}
					onChange={(e) => handleNotesChange(e.target.value)}
				/>
			</EuiFormRow>

			<EuiSpacer size={'m'} />

			<W9OnFileRadio
				onChange={props.onChange('w9OnFile', (w9OnFile: string) => w9OnFile)}
				formState={props.formState.w9OnFile}
			/>
			<EuiSpacer size={'xl'} />
		</EuiForm>
	);
}

export default EntityVendorSettingsForm;
