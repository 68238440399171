import { useAuth0 } from '@equipmentshare/auth0-react';
import { useEffect, useState } from 'react';
import { User } from 'utils/user';

type AuthenticationDetails = {
	accessToken: string;
	user?: User;
};

export function useAuthenticationDetails(): AuthenticationDetails {
	const { getAccessTokenSilently, user } = useAuth0<User>();
	const [
		authenticationDetails,
		setAuthenticationDetails,
	] = useState<AuthenticationDetails>({ accessToken: '', user });

	useEffect(() => {
		async function getAuthenticationDetails(): Promise<void> {
			const accessToken = await getAccessTokenSilently();
			setAuthenticationDetails((prev) => ({ ...prev, accessToken }));
		}

		getAuthenticationDetails();
	}, []);

	useEffect(() => {
		setAuthenticationDetails((prev) => ({ ...prev, user }));
	}, [user]);

	return authenticationDetails;
}
