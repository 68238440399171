import { EuiLink } from '@equipmentshare/ds2';
import { useHistory } from 'react-router';
import React from 'react';

type Props = {
	location: string;
	name: string;
};

const RouterLink: React.FC<React.PropsWithChildren<Props>> = (props) => {
	const history = useHistory();
	return (
		<EuiLink onClick={() => history.push(props.location)}>{props.name}</EuiLink>
	);
};

export default RouterLink;
