import {
	createEntityContactMutation,
	updateEntityContactMutation,
} from '@equipmentshare/pcrm-domain/dist/mutations/entity_contacts';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { EntityRouteParams } from '../vendor_details/vendorDetails';
import EntityContactForm, {
	EntityContactFormState,
} from '../../components/EntityContact/EntityContactForm/EntityContactForm';
import { useAuthenticationDetails } from '../../hooks/useAuthenticationDetails';
import { getPurchasesAPIHost } from '../../services/host';
import { useEntityContacts } from '@equipmentshare/pcrm-domain/dist/queries/entity_contacts';
import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import { EuiButton, EuiHorizontalRule, EuiPage } from '@equipmentshare/ds2';
import { Title } from '../../Title/Title';

export function ContactFormScreen(): ReactElement {
	const history = useHistory();
	const { accessToken } = useAuthenticationDetails();
	const { id } = useParams<EntityRouteParams>();
	const location = useLocation();
	const { data: contactsData } = useEntityContacts(
		getPurchasesAPIHost(),
		accessToken
	);
	const contactToEdit: EntityContact | undefined = useMemo(
		() =>
			contactsData?.data &&
			contactsData.data.find(
				(contact: EntityContact) => contact.entity_contact_id.toString() === id
			),
		[contactsData, id]
	);
	const {
		mutate: createContactMutate,
		status: createContactStatus,
		isLoading: createContactIsLoading,
	} = createEntityContactMutation(getPurchasesAPIHost(), accessToken);

	const {
		mutate: updateContactMutate,
		status: updateContactStatus,
		isLoading: updateContactIsLoading,
	} = updateEntityContactMutation(getPurchasesAPIHost(), accessToken);

	const [displayErrors, setDisplayErrors] = useState(false);
	const [contactIsValid, setContactIsValid] = useState(false);
	const [contactForm, setContactForm] = useState<
		EntityContactFormState | undefined
	>();

	function handleSubmit() {
		if (contactIsValid && contactForm) {
			const strippedPhoneNumber = contactForm.phoneNumber.replace(/[^\d]/g, '');
			const mutateBody = {
				first_name: contactForm.firstName,
				last_name: contactForm.lastName,
				email_address: contactForm.emailAddress || null,
				phone_number: strippedPhoneNumber,
			};
			location.pathname.includes('edit') && contactToEdit
				? updateContactMutate({
						...mutateBody,
						entity_contact_id: contactToEdit?.entity_contact_id,
				  })
				: createContactMutate(mutateBody);
		} else {
			setDisplayErrors(true);
		}
	}

	useEffect(() => {
		if (
			createContactStatus === 'success' ||
			updateContactStatus === 'success'
		) {
			history.push(`/entity-contacts`);
		}
	}, [createContactStatus, updateContactStatus]);

	return (
		<EuiPage direction="column">
			<Title
				title={
					location.pathname.includes('edit') ? 'Update Contact' : 'Add Contact'
				}
				size={'s'}
			/>
			<EuiHorizontalRule
				size={'half'}
				margin={'xs'}
				style={{ marginBottom: '24px', marginLeft: 0 }}
			/>
			<EntityContactForm
				entityContactFormChanged={setContactForm}
				entityContactToEdit={contactToEdit}
				displayErrors={displayErrors}
				setKnownContactInfo={!location.pathname.includes('edit')}
				setIsValid={setContactIsValid}
			/>
			<div style={{ marginTop: '16px' }}>
				<EuiButton
					fill={true}
					onClick={handleSubmit}
					isLoading={createContactIsLoading || updateContactIsLoading}
				>
					Submit
				</EuiButton>
			</div>
		</EuiPage>
	);
}
