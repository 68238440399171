import { EntityContact } from '@equipmentshare/pcrm-domain/dist/models';
import { ReactElement, useEffect, useState } from 'react';
import EntityContactForm, {
	EntityContactFormState,
} from '../EntityContact/EntityContactForm/EntityContactForm';
import './PrimaryContactFormContainer.css';
import {
	EuiButtonEmpty,
	EuiComboBox,
	EuiComboBoxOptionOption,
	EuiFlexItem,
	EuiFormRow,
	EuiTitle,
} from '@equipmentshare/ds2';
import Label from 'components/Label';

type PrimaryContactFormContainerProps = {
	contacts: EntityContact[];
	displayErrors: boolean;
	primaryContactFormChanged: (contact: EntityContactFormState) => void;
	primaryContactValueChanged: (contact: EntityContact | null) => void;
	selectedPrimaryContact: EntityContact | null;
	setKnownContactInfo?: boolean;
	setIsValid: (hasErrors: boolean) => void;
};

type EntityContactOption = EuiComboBoxOptionOption<EntityContact>;

const toComboBoxOptionOption = (state: EntityContact): EntityContactOption => ({
	key: state.entity_contact_id.toString(),
	value: state,
	label:
		!!state.first_name || !!state.last_name
			? `${state.first_name ?? ''} ${state.last_name ?? ''}`
			: 'Unknown Contact',
});

export default function PrimaryContactFormContainer(
	props: PrimaryContactFormContainerProps
): ReactElement {
	const [addNew, setAddNew] = useState(false);

	function addNewPressed() {
		setAddNew(true);
		props.primaryContactValueChanged(null);
	}

	function handleIsValid(isValid: boolean) {
		props.setIsValid(addNew ? isValid : true);
	}

	useEffect(() => {
		props.setIsValid(
			!!props.selectedPrimaryContact?.entity_contact_id || addNew
		);
	}, [props.selectedPrimaryContact, addNew]);

	const selectedContact = !!props.selectedPrimaryContact
		? [toComboBoxOptionOption(props.selectedPrimaryContact)]
		: [];

	const contactOptions = props.contacts.map(toComboBoxOptionOption);

	return (
		<>
			<EuiFlexItem>
				<EuiTitle size="s">
					<span>Primary Contact</span>
				</EuiTitle>
			</EuiFlexItem>
			{!addNew ? (
				<>
					<EuiFlexItem grow={false}>
						<EuiFormRow
							data-testid="contact-name-row"
							label={<Label name="Contact Name" required />}
							isInvalid={
								props.displayErrors &&
								!props.selectedPrimaryContact?.entity_contact_id
							}
							error={'Is required'}
						>
							<EuiComboBox
								data-testid="contact-name-input"
								isClearable={false}
								isInvalid={
									props.displayErrors &&
									!props.selectedPrimaryContact?.entity_contact_id
								}
								singleSelection={{ asPlainText: true }}
								selectedOptions={selectedContact}
								options={contactOptions}
								onChange={([{ value }]) =>
									props.primaryContactValueChanged(value ?? null)
								}
							/>
						</EuiFormRow>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiFormRow>
							<EuiButtonEmpty
								id="AddNewButton"
								color="primary"
								onClick={addNewPressed}
								data-testid="add-new-contact"
							>
								Add New Contact
							</EuiButtonEmpty>
						</EuiFormRow>
					</EuiFlexItem>
				</>
			) : (
				<EntityContactForm
					entityContactFormChanged={props.primaryContactFormChanged}
					displayErrors={props.displayErrors}
					setIsValid={handleIsValid}
					setKnownContactInfo={props.setKnownContactInfo}
				/>
			)}
		</>
	);
}
